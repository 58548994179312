<template>
	<BlockEcommerceProductList
		:block-id="blockId"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:products-per-page="productsPerPage"
		:column-count="columnCount"
		:product-pages="productPages"
		:product-ids="productIds"
		:product-category-id="productCategoryId"
		:is-button-enabled="isButtonEnabled"
		:button-display="buttonDisplay"
		:button-text="buttonText"
		:button-style="buttonStyle"
		:button-type="buttonType"
		:button-border-width="buttonBorderWidth"
		:is-loading="isLoading"
		:ribbon-style="ribbonStyle"
		:products="productList"
		:translations="ecommerceTranslations"
		:image-ratio="imageRatio"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:site-id="siteId"
		:variants-quantity="variantsQuantity"
		@page-changed="handlePageChange"
		@button-click="handleButtonClick"
	/>
</template>

<script setup>
import {
	computed,
	ref,
	onMounted,
} from 'vue';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';

import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		default: () => ({}),
	},
	lcp: {
		type: Object,
		default: () => ({}),
	},
	ecommerceTranslations: {
		type: Object,
		default: () => ({}),
	},
	currentLocale: {
		type: String,
		default: SYSTEM_LOCALE,
	},
	blocks: {
		type: Object,
		default: () => ({}),
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
});

const { siteId } = useSiteGlobal();

const {
	products,
	isStoreTypeZyro,
	productPages,
	isShoppingCartOpen,
	shoppingCartItems,
	variantsQuantity,
	isLoading: isEcommerceLoading,
	fetchProducts,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingId,
} = useEcommerceGlobal();

const {
	openEcommerceModal,
	closeEcommerceModal,
	setProductPreviewData,
} = useEcommerceModal(props);

const { initiateCheckout } = useEcommerce(props);

const {
	productList,
	blockStyle,
	textColorVars,
	columnCount,
	productsPerPage,
	productIds,
	productCategoryId,
	isButtonEnabled,
	buttonDisplay,
	buttonText,
	buttonStyle,
	buttonType,
	buttonBorderWidth,
	ribbonStyle,
	imageRatio,
} = useBlockEcommerceProductList(props, {
	productPages,
	isStoreTypeZyro,
	products,
});
const isClientLoaded = ref(false); // needed for astro sites to not flash loader

const isProductListShown = computed(() => !!productList.value?.length);
const isLoading = computed(() => isAppPrerendering || isEcommerceLoading.value || !isClientLoaded.value);

const handlePageChange = (idsToFetch) => {
	fetchProducts(idsToFetch);
};

const handleButtonClick = async (product) => {
	if (product.options.length) {
		const itemProductPage = Object.values(productPages.value).find((page) => page.productId === product.id);
		const ecommerceBlocks = Object.keys(props.blocks).filter((key) => props.blocks[key].type === 'BlockEcommerceProduct');
		const pageProductBlockId = ecommerceBlocks.find((block) => itemProductPage.blocks.includes(block));

		setProductPreviewData(props.blocks[pageProductBlockId]);
		openEcommerceModal('EcommerceProductPreview');

		return;
	}

	const productForShoppingCart = [
		{
			...product,
			variants: [product.variants[0]],
		},
	];

	await closeEcommerceModal();

	if (product.type.value === PRODUCT_TYPE_BOOKING) {
		setSelectedBookingId(product.id);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...productForShoppingCart,
		]);

		if (isShoppingCartOpen.value) {
			return;
		}

		setShoppingCartOpen(true);
	} else {
		setIsCheckoutLoading(true);
		await initiateCheckout(productForShoppingCart).then(() => {
			setIsCheckoutLoading(false);
		});
	}
};

onMounted(() => {
	isClientLoaded.value = true;
});

</script>
