<template>
	<BlockLayoutWrapper
		ref="blockLayout"
		:style="layoutCSSVars"
		:is-mobile-legacy="isMobileLegacy"
		is-block-responsive
		:is-forced-on-top="isSearchOpenedInCurrentBlock"
	>
		<LayoutElementProviderUser
			v-for="element in layoutElements"
			:key="element.elementId"
			:is-mobile-legacy="isMobileLegacy"
			:element-id="element.elementId"
			:element-data="element"
			:lcp="lcp"
			:is-cart-visible="isCartVisible"
			:current-locale="currentLocale"
			:site-language-pages="siteLanguagePages"
			:stripe-public-api-key="stripePublicApiKey"
			:is-parent-block-footer="isBlockFooter"
			:block-id="blockId"
		/>
	</BlockLayoutWrapper>
</template>

<script setup>
import {
	toRefs,
	computed,
} from 'vue';

import { useSearchElementDropdown } from '@zyro-inc/site-modules/utils/useSearchElementDropdown';

import BlockLayoutWrapper from '@zyro-inc/site-modules/components/blocks/layout/BlockLayoutWrapper.vue';
import { useBlockLayout } from '@zyro-inc/site-modules/components/blocks/layout/useBlockLayout';
import LayoutElementProviderUser from '@zyro-inc/site-modules/components/layout-element/LayoutElementProviderUser.vue';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		required: true,
	},
	components: {
		type: Object,
		default: () => ({}),
	},
	lcp: {
		type: Object,
		default: () => ({}),
	},
	siteLanguagePages: {
		type: Object,
		required: true,
	},
	stripePublicApiKey: {
		type: String,
		default: '',
	},
	currentLocale: {
		type: String,
		required: true,
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
});

const { isSearchOpenedInCurrentBlock } = useSearchElementDropdown({
	blockId: props.blockId,
});
const { components: siteElements } = toRefs(props);
const blockData = computed(() => props.data);
const {
	layoutElements,
	layoutCSSVars,
	isMobileLegacy,
} = useBlockLayout({
	blockData,
	siteElements,
});

const isBlockFooter = computed(() => blockData.value.slot === 'footer');

</script>
